import { useCallback } from 'react';

export function useRedirect() {
  // TODO: manage permissions or other, if necessary.
  const handleRedirect = useCallback((url: string, absolute = false) => {
    const baseUrl = process.env.REACT_APP_BACKEND_ENDPOINT;
    window.top.location.href = absolute ? url : `${baseUrl}/${url}`;
  }, []);

  return { handleRedirect };
}
