import axios from 'axios';
import { UserGroup, GroupBody, GroupHistory } from '../types';
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

interface FetchGroupsInterface {
  groups: UserGroup[];
}

async function getGroups() {
  // getMobileUsersGroups
  const response = await axios.get<FetchGroupsInterface>('groups', { withCredentials: true });
  const { data } = response;
  return data;
}

async function createGroup(body: GroupBody) {
  // createMobileUsersGroup
  const response = await axios.post<UserGroup>('/groups', body, { withCredentials: true });
  const { data } = response;
  return data;
}

async function deleteGroup(groupId: number) {
  const response = await axios.delete<UserGroup>(`/groups/${groupId}`, { withCredentials: true });
  const { data } = response;
  return data;
}

async function updateGroup(id: number, body: GroupBody) {
  // updateMobileUsersGroup
  const response = await axios.patch<UserGroup>(`/groups/${id}`, body, { withCredentials: true });
  const { data } = response;
  return data;
}

async function getHistory(page: number) {
  const response = await axios.get<GroupHistory>(`/groups/history?page=${page + 1}`, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function bulkAddUsers(groupId: number, userIds: number[]) {
  const response = await axios.put<UserGroup>(
    `/groups/bulk_add_users/${groupId}`,
    { mobile_user_ids: userIds },
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function bulkRemoveUsers(groupId: number, userIds: number[]) {
  const response = await axios.put<UserGroup>(
    `/groups/bulk_remove_users/${groupId}`,
    { user_ids: userIds },
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

export const groupsApi = {
  getGroups,
  createGroup,
  deleteGroup,
  updateGroup,
  getHistory,
  bulkAddUsers,
  bulkRemoveUsers,
};
