import { RoleOption } from '../../types';
import { rolePermissions } from './mobileUserRolePermissions';

export type PermissionVariant = 'basic' | 'advanced' | 'admin';
export type Permissions = Record<string, boolean>;
export interface PermissionsState {
  permissions: Record<PermissionVariant, Permissions>;
}

interface UpdateVariantPermissionAction {
  type: 'update_basic_permission' | 'update_advanced_permission' | 'update_admin_permission';
  permissionKey: string;
  value: boolean;
}

interface UpdatePermissionsByRoleAction {
  type: 'update_permissions_by_role';
  role: RoleOption;
  editBlockedByProject?: boolean;
}

interface ResetPermissionsAction {
  type: 'reset_permissions';
}

export type PermissionsAction =
  | UpdateVariantPermissionAction
  | UpdatePermissionsByRoleAction
  | ResetPermissionsAction;

export function PermissionsReducer(state: PermissionsState, action: PermissionsAction) {
  switch (action.type) {
    case 'update_basic_permission': {
      const updatedBasicPermissions = { ...state.permissions.basic };
      updatedBasicPermissions[action.permissionKey] = action.value;
      return {
        permissions: {
          ...state.permissions,
          basic: updatedBasicPermissions, // update the basic permissions
        },
      };
    }

    case 'update_advanced_permission': {
      const updatedAdvancedPermissions = { ...state.permissions.advanced };
      updatedAdvancedPermissions[action.permissionKey] = action.value;
      return {
        permissions: {
          ...state.permissions,
          advanced: updatedAdvancedPermissions, // update the advanced permissions
        },
      };
    }

    case 'update_admin_permission': {
      const updatedAdminPermissions = { ...state.permissions.admin };
      updatedAdminPermissions[action.permissionKey] = action.value;
      return {
        permissions: {
          ...state.permissions,
          admin: updatedAdminPermissions, // update the admin permissions
        },
      };
    }

    case 'update_permissions_by_role': {
      if (action.role === 'CUSTOMIZED') return { permissions: permissionsStateBase };
      else if (action.role === 'ADMINISTRATOR' && action.editBlockedByProject) {
        return {
          permissions: {
            ...rolePermissions[action.role],
            advanced: {
              ...rolePermissions[action.role].advanced,
              editForms: false,
            },
          },
        };
      }
      return { permissions: rolePermissions[action.role] };
    }

    case 'reset_permissions': {
      return {
        permissions: permissionsStateBase,
      };
    }
  }
}

export const permissionsStateBase = {
  basic: {
    viewFormAnswers: false,
    chat: false,
    submitFormAnswers: false,
    viewAssignedForms: false,
  },
  advanced: {
    changeFormStatus: false,
    createMetadata: false,
    editFormAnswers: false,
    editForms: false,
    evaluateDelete: false,
  },
  admin: {
    viewAndCreateUsers: false,
    exportData: false,
  },
};
