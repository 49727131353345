import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 18,
        },
        outlined: {
          background: '#FFFFFF',
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiPaper: {
      // TaskForm Table shadow
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.09)',
        },
        elevation3: {
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.09)',
        },
        elevation5: {
          boxShadow: '2px 4px 16px 0px rgba(15, 59, 96, 0.18)',
        },
      },
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: 24,
      fontWeight: 500,
    },
    h2: {
      fontSize: 18,
      fontWeight: 500,
    },
    h3: {
      fontSize: 16,
      fontWeight: 400,
    },
    h4: {
      fontSize: 14,
    },
    h5: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body1: {
      fontSize: 14,
      lineHeight: '22px',
    },
  },
  palette: {
    primary: {
      main: '#3E8FFA',
      light: '#D8EFFE',
      dark: '#1F52B3',
    },
    secondary: {
      main: '#3B3C40',
      light: '#D1D4D9', // Neutral 5
      dark: '#525459', // Neutral 7
    },
    error: {
      main: '#FF3F49',
      light: '#FFE4D8',
    },
    text: {
      primary: '#46484D',
      secondary: '#3B3C40',
    },
    background: {
      default: '#FAFBFF',
    },
  },
});
