import { rucRegex, rutRegex } from './validations';

export const module11 = (taxIdNumber: string) => {
  const taxIdNumberReversed = taxIdNumber.split('').reverse();
  const verificationDigit = taxIdNumberReversed.reduce(
    (acc, number, index) => acc + Number(number) * ((index % 6) + 2),
    0,
  );

  return 11 - (verificationDigit % 11);
};

export const getVerificationDigitRutChile = (verificationDigit: number) => {
  if (verificationDigit === 11) return '0';
  if (verificationDigit === 10) return 'K';
  return verificationDigit.toString();
};

export const checkRutChile = (rut: string) => {
  if (rut === '') return true;
  if (!rutRegex.test(rut)) return false;
  const rutSplit = rut.split('-');
  const rutNumbers = rutSplit[0].replace(/\./g, '');
  const rutVerificationDigit = rutSplit[1];
  return rutVerificationDigit === getVerificationDigitRutChile(module11(rutNumbers));
};

export const checkRucPeru = (ruc: string) => {
  if (ruc === '') return true;
  if (!rucRegex.test(ruc)) return false;
  const rucNumber = ruc.slice(0, 10);
  const rucVerificationDigit = ruc.slice(-1);
  return rucVerificationDigit === (module11(rucNumber) % 10).toString();
};

export const formatRutChile = (input: string) => {
  // ignore all characters except numbers and k/K &
  // ignore possible zeroes at the beginning of the number
  const formattedIDNumber = input.replace(/[^0-9kK]/g, '').replace(/^0+/, '');
  const idNumbers = formattedIDNumber.slice(0, -1);
  const verificationDigit = formattedIDNumber.slice(-1).toUpperCase();
  if (idNumbers === '' && verificationDigit === '') return '';
  // Add points to the RUT number
  const numbers = idNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // Join the formatted number and verification digit with a hyphen
  return numbers + '-' + verificationDigit;
};

export const formatRucPeru = (input: string) => {
  const formattedRuc = input.replace(/[^0-9]/g, '');
  if (formattedRuc === '') return '';
  return formattedRuc;
};
