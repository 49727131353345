import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '../../store/store';
import { listObjectsApi } from '../../axios/lists';
import { listObjectsType, newObjectType } from '../../types/list';

const getObjects = createAsyncThunk(
  'getObjects',
  async ({ id, metadataCode, page }: { id?: string; metadataCode?: string; page?: number }) => {
    const response = await listObjectsApi.getObjects(id, metadataCode, page);
    return { data: response.data, metadataCode };
  },
);

const createObject = createAsyncThunk(
  'createObject',
  async ({ listId, list_object }: { listId: string; list_object: newObjectType }) => {
    return await listObjectsApi.createObject(listId, list_object);
  },
);

const updateObject = createAsyncThunk(
  'updateObject',
  async ({ id, list_object }: { id: string; list_object: newObjectType }) => {
    return await listObjectsApi.updateObject(id, list_object);
  },
);

const deleteObject = createAsyncThunk('deleteObject', async ({ id }: { id: string }) => {
  return await listObjectsApi.deleteObject(id);
});

const initialState: listObjectsType = {
  objects: [],
  loading: false,
  globalObjectsTotals: {},
};

export const listObjectsSlice = createSlice({
  name: 'listObjects',
  initialState,
  reducers: {
    cleanObjects: (state) => {
      state.list = undefined;
      state.objects = [];
      state.total = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getObjects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getObjects.rejected, (state) => {
      state.objects = [];
      state.loading = false;
      state.error = 'Error at getting list objects';
    });
    builder.addCase(getObjects.fulfilled, (state, action) => {
      state.list = action.payload.data.userList;
      state.objects = action.payload.data.listObjects;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.total = action.payload.data.total;
      state.from = action.payload.data.from;
      state.to = action.payload.data.to;
      state.loading = false;
      // Add to the global objects storage:
      state.globalObjectsTotals[action.payload.metadataCode as string] = action.payload.data.total;
    });
    builder.addCase(deleteObject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteObject.rejected, (state) => {
      state.loading = false;
      state.error = 'Error at destroying object';
    });
    builder.addCase(deleteObject.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateObject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateObject.rejected, (state) => {
      state.loading = false;
      state.error = 'Error at updating object';
    });
    builder.addCase(updateObject.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createObject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createObject.rejected, (state) => {
      state.loading = false;
      state.error = 'Error at creating object';
    });
    builder.addCase(createObject.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { cleanObjects } = listObjectsSlice.actions;
export { getObjects, createObject, updateObject, deleteObject };
export const selectListObjects = (state: RootState) => state.listObjects;
export default listObjectsSlice.reducer;
