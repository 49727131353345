import { useCallback, useContext } from 'react';
import { UserDataContext } from '../providers';

export function useFeatureFlags() {
  const { featureFlags, premiumFlags, loading } = useContext(UserDataContext);

  const checkFlag = useCallback(
    (flagToCheck: string) => {
      if (!featureFlags) return false;
      return featureFlags.some((flag: string) => flagToCheck === flag);
    },
    [featureFlags],
  );

  const getPremiumFlag = useCallback(
    (flagToGet: string) => {
      if (!(flagToGet in premiumFlags)) return null;
      return premiumFlags[flagToGet];
    },
    [premiumFlags],
  );

  return {
    featureFlags,
    loading,
    checkFlag,
    getPremiumFlag,
  };
}
