import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { UserGroup, GroupBody } from '../../types';
import { groupsApi } from '../../axios/groups';
import { isAxiosError } from 'axios';

interface GroupsState {
  groups: UserGroup[];
  searchQuery?: string;
  loading: boolean;
  error?: string;
  success?: boolean;
  updateSuccess?: boolean;
}

const groupsInitialState: GroupsState = {
  groups: [],
  searchQuery: '',
  loading: true,
  updateSuccess: false,
};

// Async Thunks for API Calls:
const getGroups = createAsyncThunk('getGroups', async () => {
  return await groupsApi.getGroups();
});

const createGroup = createAsyncThunk('createGroup', async (body: GroupBody) => {
  return await groupsApi.createGroup(body);
});

const deleteGroup = createAsyncThunk(
  'deleteGroup',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      return await groupsApi.deleteGroup(id);
    } catch (error) {
      let customErrorMessage = 'Error deleting group';
      if (isAxiosError(error)) customErrorMessage = error.response?.data.message;
      return rejectWithValue(customErrorMessage);
    }
  },
);

const updateGroup = createAsyncThunk(
  'updateGroup',
  async ({ id, body }: { id: number; body: GroupBody }) => {
    return await groupsApi.updateGroup(id, body);
  },
);

const bulkAddUsersToGroup = createAsyncThunk(
  'bulkAddUsersToGroup',
  async ({ groupId, userIds }: { groupId: number; userIds: number[] }) => {
    return await groupsApi.bulkAddUsers(groupId, userIds);
  },
);

const removeGroupUsers = createAsyncThunk(
  'removeGroupUsers',
  async ({ id, userIds }: { id: number; userIds: number[] }) => {
    return await groupsApi.bulkRemoveUsers(id, userIds);
  },
);

// Mobile Users Slice with reducers:
export const groupsSlice = createSlice({
  name: 'groups',
  initialState: groupsInitialState,
  reducers: {
    updateQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get user groups:
    builder.addCase(getGroups.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getGroups.rejected, (state) => {
      state.groups = [];
      state.loading = false;
      state.error = 'Error at fetching groups';
    });
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.groups = action.payload.groups;
      state.loading = false;
      state.error = undefined;
      state.success = true;
      state.updateSuccess = false;
    });

    // Create new group:
    builder.addCase(createGroup.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(createGroup.rejected, (state) => {
      state.loading = false;
      state.error = 'Error at creating';
    });
    builder.addCase(createGroup.fulfilled, (state, action) => {
      state.groups.push(action.payload);
      state.loading = false;
      state.error = undefined;
    });

    // Delete group:
    builder.addCase(deleteGroup.pending, (state) => {
      state.loading = true;
      state.error = undefined;
      state.updateSuccess = false;
    });
    builder.addCase(deleteGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
      state.updateSuccess = false;
    });
    builder.addCase(deleteGroup.fulfilled, (state) => {
      state.loading = false;
      state.error = undefined;
      state.updateSuccess = true;
    });

    // Update group:
    builder.addCase(updateGroup.pending, (state) => {
      state.loading = true;
      state.error = undefined;
      state.updateSuccess = false;
    });
    builder.addCase(updateGroup.rejected, (state) => {
      state.loading = false;
      state.error = 'Error at updating';
      state.updateSuccess = false;
    });
    builder.addCase(updateGroup.fulfilled, (state) => {
      state.loading = false;
      state.updateSuccess = true;
    });

    // Bulk add users to group:
    builder.addCase(bulkAddUsersToGroup.pending, (state) => {
      state.loading = true;
      state.error = undefined;
      state.updateSuccess = false;
    });
    builder.addCase(bulkAddUsersToGroup.rejected, (state) => {
      state.loading = false;
      state.error = 'Error when adding users to group';
      state.updateSuccess = false;
    });
    builder.addCase(bulkAddUsersToGroup.fulfilled, (state, action) => {
      state.groups = [
        ...state.groups.filter((group) => group.id !== action.payload.id),
        action.payload,
      ];
      state.loading = false;
      state.updateSuccess = true;
    });

    // Bulk remove users from group:
    builder.addCase(removeGroupUsers.pending, (state) => {
      state.loading = true;
      state.error = undefined;
      state.updateSuccess = false;
    });
    builder.addCase(removeGroupUsers.rejected, (state) => {
      state.loading = false;
      state.error = 'Error when removing users from group';
      state.updateSuccess = false;
    });
    builder.addCase(removeGroupUsers.fulfilled, (state, action) => {
      state.groups = [
        ...state.groups.filter((group) => group.id !== action.meta.arg.id),
        action.payload,
      ];
      state.loading = false;
      state.updateSuccess = true;
    });
  },
});

export const { updateQuery } = groupsSlice.actions;
export { getGroups, deleteGroup, createGroup, updateGroup, bulkAddUsersToGroup, removeGroupUsers };
export const selectGroups = (state: RootState) => state.groups;
export default groupsSlice.reducer;
