import DSIcon from './../../datascope-icon.png';

const customStyles = {
  loader: {
    zIndex: 1001,
    marginBottom: '10px',
    animation: 'spin 4s linear infinite',
    height: '140px',
    width: '140px',
  },
} as const;

export function DSSpinner() {
  return (
    <>
      <img
        src={DSIcon}
        alt='DataScope loader'
        style={customStyles.loader}
      />
      <style>{`
                @keyframes spin {
                    0% { transform: rotate(360deg); }
                    100% { transform: rotate(0deg); }
                }
            `}</style>
    </>
  );
}
