import axios from 'axios';
import { TemplateResponse } from './../types';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

async function getTemplates(page: number, search?: string, category?: string, locale?: string) {
  const getTemplatesUrl = `/react_all_templates?page=${page + 1}${
    search ? `&search=${search}` : ''
  }${category ? `&category=${category}` : ''}${locale ? `&loc=${locale}` : ''}`;
  const response = await axios.get<TemplateResponse>(getTemplatesUrl);
  const { data } = response;
  return data;
}

async function getCategories(locale: string) {
  const getCategoriesUrl = `/react_get_categories?loc=${locale}`;
  const response = await axios.get<string[]>(getCategoriesUrl);
  const { data } = response;
  return data;
}

export const templatesApi = {
  getTemplates,
  getCategories,
};
