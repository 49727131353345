import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { TaskForm } from '../../types';
import { currentTaskFormApi } from '../../axios';

interface CurrentTaskFormState {
  currentTaskForm?: TaskForm;
  // * *currentTaskFormId* is an aux variable that only stores the id of the form
  // *   being currently displayed. It lives on the redux store
  currentTaskFormId?: number;
  publicLink?: string;
  isActive?: boolean;
  loading: boolean;
  error?: string;
  success?: boolean;
  emailSharedInvitations?: string;
  errorEmailSharedInvitations?: boolean;
}

const currentTaskFormInitialState: CurrentTaskFormState = {
  loading: false,
};

const baseBackendURL = process.env.REACT_APP_BACKEND_ENDPOINT;

// Async Thunks for API Calls:
const getTaskForm = createAsyncThunk('getTaskForm', async ({ formId }: { formId: number }) => {
  return await currentTaskFormApi.getTaskForm(formId);
});
const getPublicLink = createAsyncThunk('getPublicLink', async ({ formId }: { formId: number }) => {
  return await currentTaskFormApi.getPublicLink(formId);
});
const addFormPublicAccess = createAsyncThunk(
  'addFormPublicAccess',
  async ({ formId }: { formId: number }) => {
    return await currentTaskFormApi.addFormPublicAccess(formId);
  },
);
const removeFormPublicAccess = createAsyncThunk(
  'removeFormPublicAccess',
  async ({ formId }: { formId: number }) => {
    return await currentTaskFormApi.removeFormPublicAccess(formId);
  },
);
const shareTaskFormByEmail = createAsyncThunk(
  'shareTaskFormByEmail',
  async ({ formId, emails }: { formId: number; emails: string }) => {
    return await currentTaskFormApi.shareTaskFormByEmail(formId, emails);
  },
);

// Task Form Slice with reducers:
export const currentTaskFormSlice = createSlice({
  name: 'currentTaskForm',
  initialState: currentTaskFormInitialState,
  reducers: {
    cleanCurrentTaskForm: () => {
      return { ...currentTaskFormInitialState };
    },
    updateCurrentTaskFormId: (state, action: PayloadAction<number>) => {
      state.currentTaskFormId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get task form:
    builder.addCase(getTaskForm.pending, (state) => {
      state.loading = true;
      state.error = undefined;
      state.success = undefined;
    });
    builder.addCase(getTaskForm.rejected, (state) => {
      state.currentTaskForm = undefined;
      state.loading = false;
      state.error = 'Error at fetching the task form';
      state.success = undefined;
    });
    builder.addCase(getTaskForm.fulfilled, (state, action) => {
      state.currentTaskForm = action.payload;
      state.loading = false;
      state.error = undefined;
      state.success = undefined;
    });
    // Get public link:
    builder.addCase(getPublicLink.pending, (state) => {
      state.publicLink = undefined;
      state.loading = true;
      state.error = undefined;
      state.success = undefined;
    });
    builder.addCase(getPublicLink.rejected, (state) => {
      state.publicLink = undefined;
      state.isActive = undefined;
      state.loading = false;
      state.error = 'Error at fetching the task form public link';
      state.success = undefined;
    });
    builder.addCase(getPublicLink.fulfilled, (state, action) => {
      state.publicLink = action.payload.public_link
        ? `${baseBackendURL}${action.payload.public_link}`
        : undefined;
      state.isActive = action.payload.is_active;
      state.loading = false;
      state.error = undefined;
      state.success = undefined;
    });
    // Add public access:
    builder.addCase(addFormPublicAccess.fulfilled, (state) => {
      state.loading = true;
      state.error = undefined;
      state.isActive = true;
    });
    // Remove public access:
    builder.addCase(removeFormPublicAccess.fulfilled, (state) => {
      state.loading = true;
      state.error = undefined;
      state.publicLink = undefined;
      state.isActive = undefined;
    });
    // Share by email:
    builder.addCase(shareTaskFormByEmail.pending, (state) => {
      state.loading = true;
      state.error = undefined;
      state.emailSharedInvitations = undefined;
      state.errorEmailSharedInvitations = undefined;
    });
    builder.addCase(shareTaskFormByEmail.rejected, (state) => {
      state.loading = false;
      state.error = 'Error at sharing task form by email';
      state.emailSharedInvitations = undefined;
      state.errorEmailSharedInvitations = true;
    });
    builder.addCase(shareTaskFormByEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.emailSharedInvitations = action.payload.invitations_sent;
      state.errorEmailSharedInvitations = undefined;
    });
  },
});

export const { cleanCurrentTaskForm, updateCurrentTaskFormId } = currentTaskFormSlice.actions;
export {
  getTaskForm,
  getPublicLink,
  addFormPublicAccess,
  removeFormPublicAccess,
  shareTaskFormByEmail,
};
export const selectCurrentTaskForm = (state: RootState) => state.currentTaskForm;
export default currentTaskFormSlice.reducer;
