import axios from 'axios';
import { ExportFindingBody } from '../types';
import { ActivatedMobileUserMinimumData } from '../types';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

async function exportFinding(findingId: string, body: ExportFindingBody) {
  const url = `/findings/${findingId}/generate_pdf`;

  try {
    const response = await axios.post(url, body, {
      withCredentials: true,
    });
    const { data } = response;

    return data;
  } catch (error) {
    console.error(`Error exporting finding - ${error}`);
  }
}

async function getAllMobileUsersForFindings() {
  const response = await axios.get<ActivatedMobileUserMinimumData[]>(
    `/mobile_users_react/findings/all`,
    {
      withCredentials: true,
    },
  );
  const { data } = response;
  return data;
}

async function activateFindingsDemo() {
  const response = await axios.post(
    `/users/activate_demo_flag`,
    {
      key: 'Findings',
    },
    {
      withCredentials: true,
    },
  );
  const { data } = response;
  return data;
}

export const findingsApi = {
  exportFinding,
  getAllMobileUsersForFindings,
  activateFindingsDemo,
};
