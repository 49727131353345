import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getQueryParams } from '../../utils/utils';
import { webFormsApi } from '../../axios';
import {
  Finding,
  FormAnswerSignatureState,
  FormAnswerWithTaskAssign,
  UserSignatureState,
} from '../../types';
import { signatureApi } from '../../axios/signature';
import { useAppDispatch } from '../../store/hooks';
import { getFormAnswer } from '../../store/modules/webForms.slice';
import { FirestoreContext } from '../../providers';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { useFeatureFlags, useLanguage } from '../../hooks';
import { useSnackbar } from 'notistack';

interface SignatureState {
  user: UserSignatureState;
  form_answer: FormAnswerSignatureState;
}

interface AnswerViewContextType {
  answer: FormAnswerWithTaskAssign;
  loading: boolean;
  formId: string;
  formAnswerId: string;
  signatureState: SignatureState;
  findingsList: Finding[];
}

const AnswerViewContext = createContext<AnswerViewContextType | null>(null);

export const AnswerViewProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const {
    task_form_id: formId,
    form_answer_id: formAnswerId,
    signature_verified: signatureVerified,
  } = getQueryParams();
  const { firestore, user, firebaseConfigured } = useContext(FirestoreContext);
  const { getPremiumFlag } = useFeatureFlags();
  const { enqueueSnackbar } = useSnackbar();
  const lang = useLanguage();
  const findingsActivated = !!getPremiumFlag('findings');

  const [answer, setAnswer] = useState<FormAnswerWithTaskAssign>(null);
  const [signatureState, setSignatureState] = useState<SignatureState>(null);
  const [loading, setLoading] = useState(true);
  const [findingsList, setFindingsList] = useState<Finding[]>([]);

  const fetchFormAnswerAndTaskInfo = async () => {
    try {
      const response = await webFormsApi.getFormAnswerWithTaskData(formAnswerId);
      setAnswer(response);
    } catch (error) {
      console.error('Error fetching form answer and task info:', error);
    }
  };

  const fetchSignatureState = async () => {
    try {
      const userState = await signatureApi.getUserSignatureState();
      const formAnswerState = await signatureApi.getFormAnswerSignatureState(formAnswerId);
      setSignatureState({ user: userState, form_answer: formAnswerState });
    } catch (error) {
      console.error('Error fetching signature state:', error);
    }
  };

  const getFindings = async () => {
    if (!firebaseConfigured || !findingsActivated) return;

    try {
      const findingsQuery = query(
        collection(firestore, `datascope/${user.uid}/findings`),
        where('form_answer.id', '==', parseInt(formAnswerId)),
      );

      const findingsSnapshot = await getDocs(findingsQuery);
      const findingsList = findingsSnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as Finding;
      });
      setFindingsList(findingsList);
    } catch (error) {
      console.error('Error fetching findings:', error);
    }
  };

  useEffect(() => {
    const fetchAnswerView = async () => {
      setLoading(true);
      await fetchFormAnswerAndTaskInfo();
      await dispatch(getFormAnswer({ formAnswerId }));
      await fetchSignatureState();
      await getFindings();
      setLoading(false);
    };
    fetchAnswerView();
  }, [formAnswerId, firebaseConfigured]);

  useEffect(() => {
    if (signatureVerified === 'true')
      enqueueSnackbar(lang.answerView.signatureVerifySuccess, { variant: 'success' });
    else if (signatureVerified === 'false')
      enqueueSnackbar(lang.answerView.signatureVerifyError, { variant: 'error' });
  }, [signatureVerified]);

  const answerViewValue = {
    answer,
    loading,
    formId,
    formAnswerId,
    signatureState,
    findingsList,
  };
  return (
    <AnswerViewContext.Provider value={answerViewValue}>{children}</AnswerViewContext.Provider>
  );
};

export const useAnswerViewContext = () => useContext(AnswerViewContext);
