import axios from 'axios';
import { BillingDetails } from '../types/billing';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

async function getBillingDetails() {
  const response = await axios.get<BillingDetails>(`/billing_details`, { withCredentials: true });
  const { data } = response;
  return data;
}

async function getStripeCustomerPortalURL() {
  const response = await axios.get<{ url: string }>(`/stripe_customer_portal`, {
    withCredentials: true,
    params: { return_url: `${process.env.REACT_APP_BACKEND_ENDPOINT}/billing` },
  });
  const { data } = response;
  return data;
}

async function updateStripeUserAmount(newUsers: number, oldUsers: number) {
  const response = await axios.post(
    `/modify_subscription_react`,
    {
      n_users: newUsers,
      old_quantity: oldUsers,
    },
    {
      withCredentials: true,
    },
  );
  const { data } = response;
  return data;
}

export const billingApi = {
  getBillingDetails,
  getStripeCustomerPortalURL,
  updateStripeUserAmount,
};
