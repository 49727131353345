import { createRoot } from 'react-dom/client';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import 'object-assign-polyfill';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import packageJson from '../package.json';

if (process.env.REACT_APP_SENTRY_INIT) {
  Sentry.init({
    dsn: 'https://8cc70055f9754abe9f8ac7d227275326@o161285.ingest.sentry.io/5711496',
    release: packageJson.version,
  });
}
// eslint-disable-next-line
console.log('%cDatascope', 'color: #3E8FFA; font-size: 35px; background-color: #FAFBFF;');
console.log(
  '%cVersion: ' + packageJson.version,
  'color: #3E8FFA; font-size: 20px; background-color: #FAFBFF;',
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
