import { useEffect, useState } from 'react';
import { checkRucPeru, checkRutChile, formatRucPeru, formatRutChile } from '../utils/rutUtils';

export function useTaxId(initialTaxId: string) {
  const [value, setValue] = useState(initialTaxId);
  const [valid, setValid] = useState(true);
  const [country, setCountry] = useState('');

  const checkTaxId = (taxId: string) => {
    switch (country) {
      case 'CL':
        return checkRutChile(taxId);
      case 'PE':
        return checkRucPeru(taxId);
      default:
        return true;
    }
  };

  const formatTaxId = (taxId: string) => {
    switch (country) {
      case 'CL':
        return formatRutChile(taxId);
      case 'PE':
        return formatRucPeru(taxId);
      default:
        return taxId.replace(/[-.]/g, '');
    }
  };

  useEffect(() => {
    setTaxId(value);
  }, [country]);

  const setTaxId = (taxId: string) => {
    const formattedTaxId = formatTaxId(taxId);
    setValue(formattedTaxId);
    setValid(checkTaxId(formattedTaxId));
  };

  return { taxId: { value, valid, country }, setTaxId, setCountry };
}
