import axios from 'axios';

import {
  FormSearchResponse,
  FormTableResponse,
  TaskForm,
  TaskFormSignatureData,
  TaskFormSignatureDataBody,
  TaskFormEmailInfo,
  TaskFormFolder,
} from './../types';
import { rotateImage } from '../utils/fixRotatedImage';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

async function getTaskForms(page: number, folderId?: number, search?: string) {
  const getTaskFormsUrl = `/task_forms.json?page=${page}${
    folderId ? `&folder_id=${folderId}` : ''
  }${search ? `&search=${search}` : ''}`;
  const response = await axios.get<FormTableResponse>(getTaskFormsUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

// This request gets ALL task forms that the account has. If you use this, you MUST use virtualization.
async function getAllTaskForms(search?: string, folderId?: number) {
  const getTaskFormsUrl = `/task_forms_all${search ? `?search=${search}` : ''}${
    typeof folderId === 'number' ? `${search ? '&' : '?'}folder_id=${folderId}` : ''
  }`;
  const response = await axios.get<FormTableResponse>(getTaskFormsUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getFormSearch(page: number, search: string) {
  const getTaskFormsUrl = `/react_form_index?page=${page}&search=${search}`;
  const response = await axios.get<FormSearchResponse>(getTaskFormsUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getTaskFormsFolders() {
  const response = await axios.get<TaskFormFolder[]>('/task_forms_folders.json', {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getTaskFormsRootTable() {
  const response = await axios.get<TaskForm[]>('/task_forms_root_table.json', {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getTaskFormsTemplates() {
  try {
    const response = await axios.get<TaskForm[]>('/task_forms_templates.json', {
      withCredentials: true,
    });
    const { data } = response;
    return data;
  } catch {
    return [];
  }
}

async function toggleHideTaskForm(formId: number) {
  const response = await axios.patch<TaskForm>(
    `/task_forms/${formId}/toggle_hide_form_react`,
    {},
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function bulkUpdateFormVisibility(formIds: number[], hide: boolean) {
  const response = await axios.post<TaskForm[]>(
    `/task_forms/bulk_update_visibility`,
    {
      task_form_ids: formIds,
      hide,
    },
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function cloneTaskForm(formId: number) {
  const response = await axios.post<TaskForm>(
    `/task_forms/${formId}/clone_form_react`,
    {},
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function deleteTaskForm(formId: number) {
  const response = await axios.delete<TaskForm>(`/task_forms/${formId}/destroy_form_react`, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function bulkDeleteForms(formIds: number[]) {
  await axios.post(
    `/task_forms/bulk_delete`,
    { task_form_ids: formIds },
    { withCredentials: true },
  );
}

async function renameFolder(folderId: number, name: string) {
  const response = await axios.patch<TaskFormFolder>(
    `/form_folders/${folderId}`,
    { name },
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function createFolder(name: string, taskForms: number[]) {
  const response = await axios.post<TaskFormFolder>(
    `/form_folders`,
    { name, task_forms_ids: taskForms },
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function deleteFolder(folderId: number) {
  await axios.delete(`/form_folders/${folderId}`, { withCredentials: true });
}

async function moveForms({ formIds, destinationId }: { formIds: number[]; destinationId: number }) {
  await axios.post(
    `/task_forms/move_forms.json`,
    { task_forms_ids: formIds, folder_id: destinationId },
    { withCredentials: true },
  );
}

async function uploadImage(
  originalImage: File,
  shareToken?: string,
  resize?: boolean,
  rotate?: boolean,
) {
  const imageData = new FormData();
  const image = rotate ? await rotateImage(originalImage) : originalImage;
  imageData.append('file', image);
  if (shareToken) imageData.append('share_token', shareToken);
  if (resize) imageData.append('resize', `${resize}`);
  const response = await axios.post<{ url: string }>(
    `/upload_image_to_s3${shareToken ? `_public` : ''}`,
    imageData,
    {
      withCredentials: true,
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  );
  const { data } = response;
  return data;
}

async function toggleActivateFormSignature(formId: number) {
  const response = await axios.patch(
    `/signatures/activate_form/${formId}`,
    {},
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function getTaskFormSignatureData(formId: number) {
  const response = await axios.get<TaskFormSignatureData>(
    `/task_forms/${formId}/signatures/settings`,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function updateTaskFormSignatureData(formId: number, signatureRequestBody: TaskFormSignatureDataBody) {
  const response = await axios.put<TaskFormSignatureData>(
    `/task_forms/${formId}/signatures/settings`,
    { ...signatureRequestBody },
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function getTaskFormEmailInfo(formId: number) {
  const response = await axios.get<TaskFormEmailInfo>(`/task_forms_email_info/${formId}`, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function updateTaskFormEmailInfo({
  formId,
  emailRequestBody,
}: {
  formId: number;
  emailRequestBody: TaskFormEmailInfo;
}) {
  const response = await axios.post(
    `/task_forms_update_email_info/${formId}`,
    { ...emailRequestBody },
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function verifyFormName(formId: string, formName: string) {
  const encodedFormName = encodeURIComponent(formName);
  const response = await axios.get<{ valid: boolean }>(
    `/task_forms_verify_form_name?form_id=${formId}&form_name=${encodedFormName}`,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function getFolderFormsPendingSignatures() {
  const response = await axios.get<Record<string, number[]>>(`/form_folders_pending_signatures`, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

export const taskFormsApi = {
  getTaskForms,
  getAllTaskForms,
  toggleHideTaskForm,
  bulkUpdateFormVisibility,
  cloneTaskForm,
  deleteTaskForm,
  bulkDeleteForms,
  getTaskFormsTemplates,
  getTaskFormsFolders,
  getTaskFormsRootTable,
  getFormSearch,
  renameFolder,
  createFolder,
  deleteFolder,
  moveForms,
  uploadImage,
  toggleActivateFormSignature,
  getTaskFormSignatureData,
  updateTaskFormSignatureData,
  getTaskFormEmailInfo,
  updateTaskFormEmailInfo,
  verifyFormName,
  getFolderFormsPendingSignatures,
};
