import { useCallback, useRef } from 'react';
import { isEqual } from 'lodash';
import { FormLogChange, QuestionUnsafe } from '../types';

/* This hook is used to track changes in the form builder (for now).
  * It takes an array of objects as an argument, and returns
    an object with the FormLogChange interface as the objects change.
  * The array of objects should be Questions, and Mobile Users in the future.
*/

// TODO: change this to a generic type when we need to track changes in other objects
export function useChangeLogger() {
  // Initial States for auxiliary data tracking
  const initialChangeLog = useRef<FormLogChange | null>(null);

  const setInitialDataToTrack = (
    initialQuestions: QuestionUnsafe[],
    formTitle: string,
    formId: string,
    reset = false,
  ) => {
    if (initialChangeLog.current === null || reset) {
      // We only set this when the initial change log is empty, to avoid issues.
      initialChangeLog.current = {
        log_change: {
          title: formTitle,
          form_id: formId,
        },
      };
      initialQuestions.forEach((question, index) => {
        initialChangeLog.current!.log_change[index] = { changed: question }; // Changed is the default state.
      });
    }
  };

  // This callback is meant to be used on save on the form builder.
  const handleCompareAndLogData = useCallback(
    async (dataToTrack: QuestionUnsafe[], formTitle: string) => {
      // Auxiliary variables for the comparison:
      const newChangeLog: FormLogChange = {
        // We will save the new change log here.
        log_change: {
          title: formTitle, // Update the form title.
          form_id: initialChangeLog.current!.log_change.form_id, // The form id will never change.
        },
      };
      // --> Sets will provide us with a faster way to compare both sets of Question ids.
      const initialQuestionIds = new Set(
        Object.values(initialChangeLog.current!.log_change)
          .filter((question) => typeof question !== 'string') // filter out title and form_id --> hindrance due to the log_change type :/
          .map((question) => (question as { changed?: QuestionUnsafe }).changed?.id),
      ); // map the Question objects
      const currentQuestionIds = new Set(dataToTrack.map((question) => question.id));

      // CASE 1: Check for deleted questions
      initialQuestionIds.forEach((id) => {
        if (!currentQuestionIds.has(id)) {
          const deletedQuestionIndex = Object.values(
            initialChangeLog.current!.log_change,
          ).findIndex(
            (question) =>
              typeof question !== 'string' &&
              (question as { changed?: QuestionUnsafe }).changed?.id === id,
          );
          if (deletedQuestionIndex !== -1) {
            // We've found a deleted question!! We add it in the new change log in it's old index.
            newChangeLog.log_change[deletedQuestionIndex] = {
              deleted: { ...initialChangeLog.current!.log_change[deletedQuestionIndex].changed }, // Remember 'changed' is default!
            };
          }
        }
      });

      // CASE 2: Check for added or modified questions
      /* 
      We will use the new data (dataToTrack) as base.
      This will give us any new questions
      and also the correct order right away.
    */
      dataToTrack.forEach((question, index) => {
        const isNewQuestion = !initialQuestionIds.has(question.id);
        const isChangedTypeQuestion =
          question.type !== initialChangeLog.current!.log_change[index]?.changed?.type;

        if (isNewQuestion || isChangedTypeQuestion) {
          // The question is new or its type has changed, or the index was marked as deleted.
          // (This is an ugly fix, that is needed due to the form builder's behavior when changing a question type)
          newChangeLog.log_change[index] = { added: question };
        } else {
          // The question existed before, so we'll check for changes.
          // We will only add the attributes that have been changed:
          const oldQuestionIndex = Object.values(initialChangeLog.current!.log_change).findIndex(
            (q) =>
              typeof q !== 'string' &&
              (q as { changed?: QuestionUnsafe }).changed?.id === question.id,
          );

          const oldQuestion = initialChangeLog.current!.log_change[oldQuestionIndex].changed;
          const changedAttributes: Partial<QuestionUnsafe> = {};

          // Compare all properties of the question object
          for (const [key, value] of Object.entries(oldQuestion)) {
            if (!isEqual(value, question[key as keyof QuestionUnsafe])) {
              (changedAttributes as any)[key] = question[key as keyof QuestionUnsafe]; // save changed attribute
            }
          }

          // Only update if there are any changes
          if (Object.keys(changedAttributes).length > 0) {
            newChangeLog.log_change[index] = { changed: { id: question.id, ...changedAttributes } };
          } else {
            newChangeLog.log_change[index] = { changed: { id: question.id } }; // No changes, just the id.
          }
        }
      });

      // Sometimes users like to save and then keep editing.
      // We will update our initial change log, so we can keep track of future changes:
      setInitialDataToTrack(
        dataToTrack,
        formTitle,
        initialChangeLog.current!.log_change.form_id,
        true,
      );
      return newChangeLog; // We return the new change log to use it in the form builder update request.
    },
    [],
  );

  return {
    handleCompareAndLogData,
    setInitialDataToTrack,
  };
}
