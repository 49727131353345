import axios from 'axios';
import { newListType, newObjectType } from '../types';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

// Get lists and locations from backend
const getLists = async () => {
  try {
    const response = await axios.get('/react_get_lists', {
      withCredentials: true,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
};

const createList = async (newList: newListType) => {
  try {
    const response = await axios.post(
      '/react_create_list',
      {
        new_list: newList,
      },
      {
        withCredentials: true,
        headers: {
          accept: 'application/json',
        },
      },
    );
    return response;
  } catch (e) {
    console.log(e);
  }
};

const getObjects = async (id?: string, metadataCode?: string, page?: number) => {
  let param = '';
  if (metadataCode) param += `?metadata_code=${metadataCode}`;
  if (id) param += `?id=${id}`;
  if (page) param += `&page=${page}`;
  try {
    const response = await axios.get('/react_get_objects' + param, {
      withCredentials: true,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
};

const createObject = async (listId: string, list_object: newObjectType) => {
  try {
    return await axios.post(
      '/react_create_object',
      {
        list_object,
        list_id: listId,
      },
      {
        withCredentials: true,
      },
    );
  } catch (e) {
    console.log(e);
  }
};

const updateObject = async (id: string, list_object: newObjectType) => {
  try {
    return await axios.put(
      `/react_update_object/${id}`,
      {
        list_object,
      },
      {
        withCredentials: true,
      },
    );
  } catch (e) {
    console.log(e);
  }
};

const deleteObject = async (id: string) => {
  try {
    return await axios.delete(`/react_delete_object/${id}`, {
      withCredentials: true,
    });
  } catch (e) {
    console.log(e);
  }
};

export const listObjectsApi = {
  getObjects,
  createObject,
  updateObject,
  deleteObject,
};
export const userListsApi = { getLists, createList };
