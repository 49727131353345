import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import {
  MobileUser,
  ModificationLog,
  MobileUserCreateBody,
  MobileUserUpdateBody,
} from '../../types';
import { mobileUsersApi } from '../../axios';

interface MobileUsersState {
  mobileUsers: MobileUser[];
  modificationLogs: ModificationLog[];
  page: number; // for the table pagination
  totalMobileUsers: number;
  mobileUserSearchQuery?: string;
  totalLogs?: number;
  loading: boolean;
  error?: string;
  success?: boolean;
}

const mobileUsersInitialState: MobileUsersState = {
  mobileUsers: [],
  modificationLogs: [],
  page: 0,
  totalMobileUsers: 0,
  mobileUserSearchQuery: '',
  loading: false,
};

// Async Thunks for API Calls:
const getActivatedMobileUsers = createAsyncThunk(
  'getActivatedMobileUsers',
  async (
    _,
    { getState }, // This comes from the payloadCreator. See https://redux-toolkit.js.org/api/createAsyncThunk#payloadcreator
  ) => {
    const store = getState() as Record<string, MobileUsersState>;
    return await mobileUsersApi.getActivatedMobileUsers({
      search: store.mobileUsers.mobileUserSearchQuery,
      page: store.mobileUsers.page + 1, // We need to +1 because MUI pagination starts on 0 and backend pagination starts on 1.
    });
  },
);

const getDeactivatedMobileUsers = createAsyncThunk(
  'getDeactivatedMobileUsers',
  async (
    _,
    { getState }, // This comes from the payloadCreator. See https://redux-toolkit.js.org/api/createAsyncThunk#payloadcreator
  ) => {
    const store = getState() as Record<string, MobileUsersState>;
    return await mobileUsersApi.getDeactivatedMobileUsers(
      { page: store.mobileUsers.page + 1 }, // We need to +1 because MUI pagination starts on 0 and backend pagination starts on 1.
    );
  },
);

const getMobileUsersHistory = createAsyncThunk(
  'getMobileUsersHistory',
  async (
    _,
    { getState }, // This comes from the payloadCreator. See https://redux-toolkit.js.org/api/createAsyncThunk#payloadcreator
  ) => {
    const store = getState() as Record<string, MobileUsersState>;
    return await mobileUsersApi.getMobileUsersHistory(
      { page: store.mobileUsers.page + 1 }, // We need to +1 because MUI pagination starts on 0 and backend pagination starts on 1.
    );
  },
);

const deleteMobileUser = createAsyncThunk(
  'deleteMobileUser',
  async ({ mobileUserId }: { mobileUserId: number }) => {
    return await mobileUsersApi.deleteMobileUser(mobileUserId);
  },
);

const bulkDeactivateMobileUsers = createAsyncThunk(
  'bulkDeactivateMobileUsers',
  async ({ mobileUserIds }: { mobileUserIds: number[] }) => {
    return await mobileUsersApi.bulkDeactivateMobileUsers(mobileUserIds);
  },
);

const reactivateMobileUser = createAsyncThunk(
  'reactivateMobileUser',
  async ({ mobileUserId }: { mobileUserId: number }) => {
    return await mobileUsersApi.reactivateMobileUser(mobileUserId);
  },
);

const createMobileUser = createAsyncThunk(
  'createMobileUser',
  async (body: MobileUserCreateBody) => {
    return await mobileUsersApi.createMobileUser(body);
  },
);

const updateMobileUser = createAsyncThunk(
  'updateMobileUser',
  async (body: MobileUserUpdateBody) => {
    return await mobileUsersApi.updateMobileUser(body);
  },
);

// Mobile Users Slice with reducers:
export const mobileUsersSlice = createSlice({
  name: 'mobileUsers',
  initialState: mobileUsersInitialState,
  reducers: {
    cleanMobileUsers: () => {
      return { ...mobileUsersInitialState };
    },
    updateMobileUsersPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    updateMobileUserSearchQuery: (state, action: PayloadAction<string>) => {
      state.mobileUserSearchQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Activated users:
    builder.addCase(getActivatedMobileUsers.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getActivatedMobileUsers.rejected, (state) => {
      state.mobileUsers = [];
      state.loading = false;
      state.error = 'Error at fetching activated mobile users';
    });
    builder.addCase(getActivatedMobileUsers.fulfilled, (state, action) => {
      state.mobileUsers = action.payload.users;
      state.totalMobileUsers = action.payload.total_users;
      state.loading = false;
      state.error = undefined;
    });

    // Get Deactivated users:
    builder.addCase(getDeactivatedMobileUsers.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getDeactivatedMobileUsers.rejected, (state) => {
      state.mobileUsers = [];
      state.loading = false;
      state.error = 'Error at fetching deactivated mobile users';
    });
    builder.addCase(getDeactivatedMobileUsers.fulfilled, (state, action) => {
      state.mobileUsers = action.payload.users;
      state.totalMobileUsers = action.payload.total_users;
      state.loading = false;
      state.error = undefined;
    });

    // Get history of users:
    builder.addCase(getMobileUsersHistory.pending, (state) => {
      state.mobileUsers = []; // Clean mobile users, we will not use them in this tab
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getMobileUsersHistory.rejected, (state) => {
      state.modificationLogs = [];
      state.totalMobileUsers = 0;
      state.totalLogs = 0;
      state.loading = false;
      state.error = 'Error at fetching mobile users history';
    });
    builder.addCase(getMobileUsersHistory.fulfilled, (state, action) => {
      state.modificationLogs = action.payload.users;
      state.totalLogs = action.payload.total_users;
      state.loading = false;
      state.error = undefined;
    });

    // Delete (deactivate) a mobile user:
    builder.addCase(deleteMobileUser.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(deleteMobileUser.rejected, (state) => {
      state.mobileUsers = [];
      state.loading = false;
      state.error = 'Error at deactivating mobile users';
    });
    builder.addCase(deleteMobileUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      const filteredMobileUsers = [...state.mobileUsers].filter(
        (user) => user.id !== action.payload.id,
      );
      state.totalMobileUsers--;
      state.mobileUsers = filteredMobileUsers;
      // Check if the page must be changed:
      if (filteredMobileUsers.length % 20 === 0) state.page--;
      state.success = true;
    });

    // Bulk deactivate mobile users:
    builder.addCase(bulkDeactivateMobileUsers.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(bulkDeactivateMobileUsers.rejected, (state) => {
      state.loading = false;
      state.mobileUsers = [];
      state.error = 'Error at deactivating mobile users';
    });
    builder.addCase(bulkDeactivateMobileUsers.fulfilled, (state, { meta }) => {
      state.loading = false;
      state.error = undefined;
      const filteredMobileUsers = [...state.mobileUsers].filter(
        (user) => !meta.arg.mobileUserIds.includes(user.id),
      );
      state.totalMobileUsers -= meta.arg.mobileUserIds.length;
      state.mobileUsers = filteredMobileUsers;
      // Check if the page must be changed:
      if (filteredMobileUsers.length % 20 === 0) state.page--;
      state.success = true;
    });

    // Reactivate a mobile user:
    builder.addCase(reactivateMobileUser.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(reactivateMobileUser.rejected, (state) => {
      state.loading = false;
      state.error = 'Error at activating mobile users';
    });
    builder.addCase(reactivateMobileUser.fulfilled, (state, action) => {
      // Activating a user means removing it from the deactivated table (which we are currently on)
      state.loading = false;
      state.error = undefined;
      const filteredMobileUsers = [...state.mobileUsers].filter(
        (user) => user.id !== action.payload.id,
      );
      state.totalMobileUsers--;
      state.mobileUsers = filteredMobileUsers;
      // Check if the page must be changed:
      if (filteredMobileUsers.length % 20 === 0) state.page--;
      state.success = true;
    });

    // Create a mobile user:
    builder.addCase(createMobileUser.pending, (state) => {
      state.loading = true;
      state.error = undefined;
      state.success = undefined;
    });
    builder.addCase(createMobileUser.rejected, (state) => {
      state.loading = false;
      state.error = 'Error at activating mobile users';
      state.success = false;
    });
    builder.addCase(createMobileUser.fulfilled, (state) => {
      state.loading = false;
      state.error = undefined;
      state.success = true;
    });

    // Update mobile user:
    builder.addCase(updateMobileUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMobileUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateMobileUser.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { cleanMobileUsers, updateMobileUsersPage, updateMobileUserSearchQuery } =
  mobileUsersSlice.actions;
export {
  getActivatedMobileUsers,
  getDeactivatedMobileUsers,
  getMobileUsersHistory,
  deleteMobileUser,
  bulkDeactivateMobileUsers,
  reactivateMobileUser,
  createMobileUser,
  updateMobileUser,
};
export const selectMobileUsers = (state: RootState) => state.mobileUsers;
export default mobileUsersSlice.reducer;
