import { DateObject } from 'react-multi-date-picker';
import { CreateTaskAssignBody } from '../../../types';
import { TaskNotification } from './addnotification';

type TaskAssignedValue<T> = {
  value: T;
  showError: boolean;
  missing?: boolean;
};

export interface TaskAssigned {
  users: TaskAssignedValue<number[]>;
  forms: TaskAssignedValue<number[]>;
  location: TaskAssignedValue<number>;
  description: TaskAssignedValue<string>;
  gap: TaskAssignedValue<number>;
  subTasks: TaskAssignedValue<string[]>;
  dates: TaskAssignedValue<Date[]>;
  hour: TaskAssignedValue<Date>;
  notifications: TaskAssignedValue<TaskNotification[]>;
  required?: TaskAssignedValue<CreateTaskAssignBody['task_assign']['mandatory']>;
  canSubmitLate?: TaskAssignedValue<boolean>;
}

type ValueChangedAction<K extends keyof TaskAssigned> = {
  type: 'value_changed';
  payload: { key: K; value: TaskAssigned[K]['value'] };
};

type BlurTriggeredAction = {
  type: 'blur_triggered';
  payload: { key: Exclude<keyof TaskAssigned, 'taskResume'> };
};

type TaskDataLoadedAction = {
  type: 'task_data_loaded';
  payload: TaskAssigned;
};

type AddNotificationAction = {
  type: 'add_notification';
  payload: TaskNotification;
};

type RemoveNotificationAction = {
  type: 'remove_notification';
  payload: number;
};

type UpdateNotificationAction = {
  type: 'update_notification';
  payload: {
    index: number;
    key: keyof TaskNotification;
    value: TaskNotification[keyof TaskNotification];
  };
};

export type TaskAssignerActions =
  | ValueChangedAction<keyof TaskAssigned>
  | BlurTriggeredAction
  | TaskDataLoadedAction
  | AddNotificationAction
  | RemoveNotificationAction
  | UpdateNotificationAction;

export const initialState: TaskAssigned = {
  users: {
    value: [],
    showError: false,
    missing: true,
  },
  forms: {
    value: [],
    showError: false,
    missing: true,
  },
  location: {
    value: 0,
    showError: false,
    missing: false,
  },
  description: { value: '', showError: false, missing: false },
  dates: { value: [new Date()], showError: false, missing: false },
  hour: { value: new Date(), showError: false, missing: false },
  gap: { value: 4, showError: false, missing: false },
  subTasks: { value: [], showError: false, missing: false },
  notifications: { value: [], showError: false, missing: false },
  required: { value: 2, showError: false, missing: false },
  canSubmitLate: { value: false, showError: false, missing: false },
};
const checkRequiredValues = (key: keyof TaskAssigned, task: TaskAssigned) => {
  if (key === 'users' || key === 'forms' || key === 'dates') {
    return task[key].value.length === 0;
  } else if (key === 'hour') {
    return !task[key].value;
  } else if (key === 'gap') {
    return !task[key].value;
  } else if (key === 'notifications') {
    return task[key].value.some((notif) => !notif.type || !notif.period);
  }
  return false;
};

export const TaskAssignerReducer = (
  state: TaskAssigned,
  action: TaskAssignerActions,
): TaskAssigned => {
  switch (action.type) {
    case 'blur_triggered': {
      const isMissing = checkRequiredValues(action.payload.key, state);
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          showError: isMissing,
          missing: isMissing,
        },
      };
    }
    case 'value_changed': {
      let newValue = action.payload.value;
      let missing = false;

      if (action.payload.key === 'location' && action.payload.value === state.location.value) {
        newValue = initialState.location.value;
      }
      if (action.payload.key === 'forms' && (action.payload.value as number[]).length === 0) {
        // Forms has no blur event, so we need to check it here
        missing = true;
      }

      return {
        ...state,
        [action.payload.key]: {
          value: newValue,
          showError: false,
          missing,
        },
      };
    }
    case 'task_data_loaded': {
      return action.payload;
    }
    case 'add_notification': {
      return {
        ...state,
        notifications: {
          value: [...state.notifications.value, action.payload],
          showError: false,
          missing: false,
        },
      };
    }
    case 'remove_notification': {
      return {
        ...state,
        notifications: {
          value: state.notifications.value.filter((_, i) => i !== action.payload),
          showError: false,
          missing: false,
        },
      };
    }
    case 'update_notification': {
      return {
        ...state,
        notifications: {
          value: state.notifications.value.map((notification, index) =>
            index === action.payload.index
              ? { ...notification, [action.payload.key]: action.payload.value }
              : notification,
          ),
          showError: false,
          missing: false,
        },
      };
    }
    default:
      return state;
  }
};
