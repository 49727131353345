import axios from 'axios';
import { TaskForm } from './../types';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

async function getTaskForm(formId: number) {
  const getTaskFormUrl = `/task_forms_get/${formId}`;
  const response = await axios.get<TaskForm>(getTaskFormUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

interface GetPublicLinkResponse {
  is_active: boolean;
  public_link: string;
}
async function getPublicLink(formId: number) {
  const response = await axios.get<GetPublicLinkResponse>(`/react_get_public_access/${formId}`, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function addFormPublicAccess(formId: number) {
  const getTaskFormUrl = `/add_public_access?id=${formId}&react=react`;
  const response = await axios.get<TaskForm>(getTaskFormUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function removeFormPublicAccess(formId: number) {
  const getTaskFormUrl = `/remove_public_access?id=${formId}&react=react`;
  const response = await axios.get<TaskForm>(getTaskFormUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

interface ShareTaskFormByEmailResponse {
  invitations_sent: string;
}
async function shareTaskFormByEmail(formId: number, emails: string) {
  const response = await axios.post<ShareTaskFormByEmailResponse>(
    '/share_form',
    {
      emails,
      react: 'react',
      id: formId,
    },
    {
      withCredentials: true,
      headers: {
        accept: 'application/json',
      },
    },
  );
  const { data } = response;
  return data;
}

export const currentTaskFormApi = {
  getTaskForm,
  getPublicLink,
  shareTaskFormByEmail,
  addFormPublicAccess,
  removeFormPublicAccess,
};
