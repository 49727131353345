import { useContext } from 'react';
import { UserDataContext } from '../providers';

export function useProjectConfig() {
  const { currentUser } = useContext(UserDataContext);

  return {
    blockFormBuilder: currentUser?.project?.block_editing_creating_forms,
    formsAllowedPerAccount: currentUser?.project?.forms_allowed_per_account,
    projectName: currentUser?.project?.name ?? '',
  };
}
