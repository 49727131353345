import axios from 'axios';
import { FormAnswersResponse } from '../types';
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

async function getFormAnswers(searchQuery?: string): Promise<FormAnswersResponse> {
  const encodedQuery = searchQuery ? encodeURIComponent(searchQuery) : '';
  const response = await axios.get<FormAnswersResponse>(
    `/form_answers_react${encodedQuery ? `?search_form_name=${encodedQuery}` : ''}`,
    {
      withCredentials: true,
    },
  );
  const { data } = response;
  return data;
}

export const formAnswersApi = {
  getFormAnswers,
};
