import axios from 'axios';
import { Location, LocationResponse } from '../types';
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

const getLocations = async (page: number) => {
  const getLocationsUrl = `/react_get_locations${page ? `?page=${page}` : ''}`;
  const response = await axios.get<LocationResponse>(getLocationsUrl, {
    withCredentials: true,
  }); // TODO: add typing
  return response;
};

const getLocationsWithSearch = async (page: number, search?: string) => {
  const getLocationsUrl = `/v2/react_get_locations${page ? `?page=${page}` : ''}${
    search ? `&search=${search}` : ''
  }`;
  const response = await axios.get<LocationResponse>(getLocationsUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
};

const getLocation = async (locationId: number) => {
  const getLocationUrl = `/react_get_location/${locationId}`;
  const response = await axios.get<Location>(getLocationUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
};

export const locationsApi = { getLocations, getLocationsWithSearch, getLocation };
