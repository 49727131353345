import { ReactNode, createContext, useEffect, useRef, useState } from 'react';
import { CurrentUser, PremiumFlag, currentUserApi } from '../axios';

export interface UserDataContextType {
  permissions: string[];
  featureFlags: string[];
  premiumFlags: Record<string, PremiumFlag>;
  loading: boolean;
  currentUser: CurrentUser;
}

export const UserDataContext = createContext<UserDataContextType | null>(null);

export const UserDataProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = useRef<CurrentUser>(null);

  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState<UserDataContextType['permissions']>([]);
  const [featureFlags, setFeatureFlags] = useState<UserDataContextType['featureFlags']>([]);
  const [premiumFlags, setPremiumFlags] = useState<UserDataContextType['premiumFlags']>({});

  useEffect(() => {
    setLoading(true);
    Promise.all([
      currentUserApi.getCurrentUserPermissions(),
      currentUserApi.getCurrentUserFlags(),
      currentUserApi.getCurrentUserName(),
    ]).then(([permissions, flagsResponse, user]) => {
      setPermissions(permissions);
      setFeatureFlags(flagsResponse.flags);
      if (flagsResponse.premium_flags) setPremiumFlags(flagsResponse.premium_flags);
      currentUser.current = user;
      setLoading(false);
    });
  }, []);

  return (
    <UserDataContext.Provider
      value={{
        permissions,
        featureFlags,
        premiumFlags,
        loading,
        currentUser: currentUser.current,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
