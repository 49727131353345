import type { RootState } from '../store';
import { TaskForm } from '../../types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taskFormsApi } from '../../axios';
import omit from 'lodash/omit';

interface FormFolderTable {
  // data state for a single folder
  id: number; // 0 for the main table
  name: string;
  taskForms: TaskForm[];
  allFormsIds: number[];
  totalForms: number;
  totalFoundForms: number; // Only used for search
  pendingSignatureForms?: number[];

  // table ui state
  visible: boolean;
  page: number;
  loading: boolean;
  open: boolean;
  selectedForms: number[]; // form ids

  // loaded state
  updated: boolean;
}

interface TaskFormsState {
  formFolders?: {
    [id: number]: FormFolderTable;
  };
  taskForms: TaskForm[];

  loading: boolean;
  formSearchQuery: string;
}

const taskFormsInitialState: TaskFormsState = {
  formFolders: {
    0: {
      id: 0,
      name: 'main-table', // not displayed, useful for debugging
      taskForms: [],
      allFormsIds: [],
      totalForms: 0,
      totalFoundForms: 0,
      pendingSignatureForms: [],

      visible: true,
      page: 0,
      loading: false,
      open: true,
      selectedForms: [],

      updated: false,
    },
  },
  formSearchQuery: '',
  taskForms: [],
  loading: false,
};

const getAllTaskForms = createAsyncThunk('getAllTaskForms', async (_, { getState }) => {
  const store = getState() as Record<string, TaskFormsState>;
  return await taskFormsApi.getAllTaskForms(store.taskForms.formSearchQuery);
});

// Async Thunks for API Calls:
const getFolders = createAsyncThunk('getFolders', async () => {
  return await taskFormsApi.getTaskFormsFolders();
});

const toggleHideTaskForm = createAsyncThunk(
  'toggleHideTaskForm',
  // folderID ts not used, but it's necessary to be used in the extraReducers
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ formId, folderId }: { formId: number; folderId: number }) => {
    return await taskFormsApi.toggleHideTaskForm(formId);
  },
);

const bulkUpdateFormVisibility = createAsyncThunk(
  'bulkUpdateFormVisibility',
  // folderID ts not used, but it's necessary to be used in the extraReducers
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ formIds, folderId, hide }: { formIds: number[]; folderId: number; hide: boolean }) => {
    return await taskFormsApi.bulkUpdateFormVisibility(formIds, hide);
  },
);

const cloneTaskForm = createAsyncThunk(
  'cloneTaskForm',
  // folderID ts not used, but it's necessary to be used in the extraReducers
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ formId, folderId }: { formId: number; folderId: number }) => {
    return await taskFormsApi.cloneTaskForm(formId);
  },
);

const deleteTaskForm = createAsyncThunk(
  'deleteTaskForm',
  // folderID ts not used, but it's necessary to be used in the extraReducers
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ formId, folderId }: { formId: number; folderId: number }) => {
    return await taskFormsApi.deleteTaskForm(formId);
  },
);

const bulkDeleteForms = createAsyncThunk(
  'bulkDeleteForms',
  // folderID ts not used, but it's necessary to be used in the extraReducers
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ formIds, folderId }: { formIds: number[]; folderId: number }) => {
    return await taskFormsApi.bulkDeleteForms(formIds);
  },
);

const renameFolder = createAsyncThunk(
  'renameFolder',
  async ({ folderId, name }: { folderId: number; name: string }) => {
    return await taskFormsApi.renameFolder(folderId, name);
  },
);

const createFolder = createAsyncThunk(
  'createFolder',
  async ({ name, formIds }: { name: string; formIds: number[] }) => {
    return await taskFormsApi.createFolder(name, formIds);
  },
);

const deleteFolder = createAsyncThunk(
  'deleteFolder',
  async ({ folderId }: { folderId: number }) => {
    return await taskFormsApi.deleteFolder(folderId);
  },
);

const moveForms = createAsyncThunk(
  'moveForms',
  async ({
    formIds,
    // Its not used, but it's necessary to be used in the extraReducers
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    originId,
    destinationId,
  }: {
    formIds: number[];
    originId: number;
    destinationId: number;
  }) => {
    return await taskFormsApi.moveForms({ formIds, destinationId });
  },
);

const getFolderForms = createAsyncThunk(
  'getFolderForms',
  async (folderId: number, { getState }) => {
    const store = getState() as Record<string, TaskFormsState>;
    const folder = store.taskForms.formFolders[folderId];
    return await taskFormsApi.getTaskForms(
      folder.page + 1, // We need to +1 because MUI pagination starts on 0 and backend pagination starts on 1.
      folderId,
      store.taskForms.formSearchQuery,
    );
  },
);

const getAllFolderForms = createAsyncThunk('getAllFolderForms', async (folderId: number) => {
  return await taskFormsApi.getAllTaskForms(null, folderId);
});

const searchForms = createAsyncThunk('searchForms', async (_, { getState }) => {
  const store = getState() as Record<string, TaskFormsState>;
  return await taskFormsApi.getFormSearch(1, store.taskForms.formSearchQuery);
});

const getFoldersWithPendingSignatures = createAsyncThunk(
  'getFoldersWithPendingSignatures',
  async () => {
    return await taskFormsApi.getFolderFormsPendingSignatures();
  },
);

// Task Form Slice with reducers:
export const taskFormsSlice = createSlice({
  name: 'taskForms',
  initialState: taskFormsInitialState,
  reducers: {
    cleanTaskForms: () => {
      return { ...taskFormsInitialState };
    },
    updateFormSearchQuery: (state, action: PayloadAction<string>) => {
      state.formSearchQuery = action.payload;
    },
    clearFormSearch: (state) => {
      state.formSearchQuery = '';
      state.formFolders = Object.values(state.formFolders).reduce((formFolders, folder) => {
        return {
          ...formFolders,
          [folder.id]: {
            ...folder,
            page: 0,
            visible: true,
            open: folder.id === 0,
            updated: false,
          },
        };
      }, state.formFolders);
    },
    updateFormFolderPage: (state, action: PayloadAction<{ folderId?: number; page: number }>) => {
      state.formFolders[action.payload.folderId].page = action.payload.page;
      state.formFolders[action.payload.folderId].selectedForms = [];
    },
    toggleOpenFolder: (state, action: PayloadAction<number>) => {
      state.formFolders[action.payload].open = !state.formFolders[action.payload].open;
    },
    toggleSelectedForm: (state, action: PayloadAction<{ folderId: number; formId: number }>) => {
      const folder = state.formFolders[action.payload.folderId];
      if (folder.selectedForms.includes(action.payload.formId)) {
        folder.selectedForms = folder.selectedForms.filter(
          (formId) => formId !== action.payload.formId,
        );
      } else {
        folder.selectedForms.push(action.payload.formId);
      }
    },
    toggleAllSelectedForms: (state, action: PayloadAction<number>) => {
      const folder = state.formFolders[action.payload];
      if (folder.selectedForms.length === 0) {
        folder.selectedForms = folder.taskForms.map((form) => form.id);
      } else {
        folder.selectedForms = [];
      }
    },
  },
  extraReducers: (builder) => {
    //* Get ALL task forms:
    builder.addCase(getAllTaskForms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTaskForms.rejected, (state) => {
      state.taskForms = [];
      state.loading = false;
    });
    builder.addCase(getAllTaskForms.fulfilled, (state, action) => {
      state.taskForms = action.payload.forms;
      state.loading = false;
    });

    //* Get folders:
    builder.addCase(getFolders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFolders.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getFolders.fulfilled, (state, { payload }) => {
      state.formFolders = payload.reduce((formFolders, folder) => {
        return {
          ...formFolders,
          [folder.id]: {
            id: folder.id,
            name: folder.name,
            taskForms: formFolders[folder.id]?.taskForms ?? [],
            allFormsIds: folder.forms,
            totalForms: folder.total_forms,
            totalFoundForms: formFolders[folder.id]?.totalFoundForms ?? 0,

            visible: formFolders[folder.id]?.visible ?? true,
            page: formFolders[folder.id]?.page ?? 0,
            loading: false,
            open: formFolders[folder.id]?.open ?? false,
            selectedForms: formFolders[folder.id]?.selectedForms ?? [],

            updated: false,
          },
        };
      }, state.formFolders);
      state.loading = false;
    });

    //* Rename folder:
    builder.addCase(renameFolder.pending, (state, { meta }) => {
      state.formFolders[meta.arg.folderId].loading = true;
    });
    builder.addCase(renameFolder.rejected, (state, { meta }) => {
      state.formFolders[meta.arg.folderId].loading = false;
    });
    builder.addCase(renameFolder.fulfilled, (state, action) => {
      state.formFolders[action.payload.id].name = action.payload.name;
      state.formFolders[action.payload.id].loading = false;
    });

    //* Create Folder:
    builder.addCase(createFolder.pending, (state) => {
      state.formFolders[0].loading = true;
    });
    builder.addCase(createFolder.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createFolder.fulfilled, (state, { payload, meta }) => {
      state.formFolders = {
        ...state.formFolders,
        [payload.id]: {
          id: payload.id,
          name: payload.name,
          taskForms: [],
          totalForms: payload.total_forms,
          allFormsIds: payload.forms,
          totalFoundForms: 0,

          visible: true,
          page: 0,
          loading: false,
          updated: false,
          open: false,
          selectedForms: [],
        },
      };
      state.formFolders[0].taskForms = state.formFolders[0].taskForms.filter((form) => {
        return !meta.arg.formIds.includes(form.id);
      });
      state.formFolders[0].loading = false;
      state.formFolders[0].totalForms = state.formFolders[0].taskForms.length;
    });

    //* Delete Folder:
    builder.addCase(deleteFolder.pending, (state, { meta }) => {
      state.formFolders[meta.arg.folderId].loading = true;
      state.formFolders[0].loading = true;
      state.formFolders[0].updated = undefined;
    });
    builder.addCase(deleteFolder.rejected, (state, { meta }) => {
      state.formFolders[meta.arg.folderId].loading = true;
    });
    builder.addCase(deleteFolder.fulfilled, (state, { meta }) => {
      state.formFolders[meta.arg.folderId].loading = false;
      state.formFolders = omit(state.formFolders, meta.arg.folderId);
    });

    //* Toggle visibility:
    builder.addCase(toggleHideTaskForm.pending, (state, { meta }) => {
      const formObject = state.formFolders[meta.arg.folderId];
      formObject.loading = true;
      formObject.updated = undefined;
    });
    builder.addCase(toggleHideTaskForm.rejected, (state, { meta }) => {
      const formObject = state.formFolders[meta.arg.folderId];
      formObject.loading = false;
      formObject.updated = undefined;
    });
    builder.addCase(toggleHideTaskForm.fulfilled, (state, { meta, payload }) => {
      const formObject = state.formFolders[meta.arg.folderId];
      const editedTaskFormIndex = formObject.taskForms.findIndex(
        (taskForm) => taskForm.id === payload.id,
      );
      const newTaskFormsState = [...formObject.taskForms];
      newTaskFormsState[editedTaskFormIndex] = payload;
      formObject.taskForms = newTaskFormsState;
      formObject.loading = false;
      formObject.updated = true;
    });

    //* Bulk Update Visibility:
    builder.addCase(bulkUpdateFormVisibility.pending, (state, { meta }) => {
      const formObject = state.formFolders[meta.arg.folderId];
      formObject.loading = true;
      formObject.updated = undefined;
    });
    builder.addCase(bulkUpdateFormVisibility.rejected, (state, { meta }) => {
      const formObject = state.formFolders[meta.arg.folderId];
      formObject.loading = false;
      formObject.updated = undefined;
    });
    builder.addCase(bulkUpdateFormVisibility.fulfilled, (state, { meta }) => {
      const formTable = state.formFolders[meta.arg.folderId];
      formTable;

      formTable.taskForms = formTable.taskForms.reduce((taskForms, taskForm) => {
        if (meta.arg.formIds.includes(taskForm.id)) {
          return [...taskForms, { ...taskForm, hidden: meta.arg.hide }];
        } else {
          return [...taskForms, taskForm];
        }
      }, []);
      formTable.selectedForms = [];
      formTable.loading = false;
      formTable.updated = true;
    });

    //* Clone Task Form:
    builder.addCase(cloneTaskForm.pending, (state, { meta }) => {
      const formObject = state.formFolders[meta.arg.folderId];
      formObject.loading = true;
      formObject.updated = undefined;
    });
    builder.addCase(cloneTaskForm.rejected, (state, { meta }) => {
      const formObject = state.formFolders[meta.arg.folderId];
      formObject.loading = false;
      formObject.updated = undefined;
    });
    builder.addCase(cloneTaskForm.fulfilled, (state, { meta, payload }) => {
      const formObject = state.formFolders[meta.arg.folderId];
      const newTaskFormsState = [...formObject.taskForms];
      if (newTaskFormsState.length < 20) {
        newTaskFormsState.push(payload);
        formObject.taskForms = newTaskFormsState;
      }
      formObject.loading = false;
      formObject.updated = true;
      formObject.totalForms++;
      if (state.formSearchQuery) formObject.totalFoundForms++;
    });

    //* Delete Task Form:
    builder.addCase(deleteTaskForm.pending, (state, { meta }) => {
      const formFolder = state.formFolders[meta.arg.folderId];
      formFolder.loading = true;
      formFolder.updated = undefined;
    });
    builder.addCase(deleteTaskForm.rejected, (state, { meta }) => {
      const formFolder = state.formFolders[meta.arg.folderId];
      formFolder.loading = false;
      formFolder.updated = undefined;
    });
    builder.addCase(deleteTaskForm.fulfilled, (state, { meta, payload }) => {
      const formFolder = state.formFolders[meta.arg.folderId];
      const filteredTaskForms = [...formFolder.taskForms].filter(
        (taskForm) => taskForm.id !== payload.id,
      );
      formFolder.taskForms = filteredTaskForms;
      formFolder.totalForms--;

      // Hide folder if on search and the last form of the folder:
      if (
        formFolder.totalForms > 0 &&
        state.formSearchQuery &&
        formFolder.totalFoundForms === 0 &&
        meta.arg.folderId !== 0
      ) {
        state.formFolders[meta.arg.folderId].visible = false;
      }

      // Check if the page must be changed:
      if (state.formSearchQuery) {
        formFolder.totalFoundForms--;
        if (formFolder.totalFoundForms % 20 === 0 && formFolder.page > 0) formFolder.page--;
      } else {
        if (formFolder.totalForms % 20 === 0 && formFolder.page > 0) formFolder.page--;
      }
    });

    //* Bulk Delete Task Forms:
    builder.addCase(bulkDeleteForms.pending, (state, { meta }) => {
      const formFolder = state.formFolders[meta.arg.folderId];
      formFolder.loading = true;
      formFolder.updated = undefined;
    });
    builder.addCase(bulkDeleteForms.rejected, (state, { meta }) => {
      const formFolder = state.formFolders[meta.arg.folderId];
      formFolder.loading = false;
      formFolder.updated = undefined;
    });
    builder.addCase(bulkDeleteForms.fulfilled, (state, { meta }) => {
      const formFolder = state.formFolders[meta.arg.folderId];
      // handle possible pagination change
      formFolder.totalForms -= meta.arg.formIds.length;
      // Change page if it's the last form in the page (page > 0):
      if (state.formSearchQuery) {
        formFolder.totalFoundForms -= meta.arg.formIds.length;
        if (formFolder.totalFoundForms % 20 === 0 && formFolder.page > 0) formFolder.page--;
      } else {
        if (formFolder.totalForms % 20 === 0 && formFolder.page > 0) formFolder.page--;
      }
      formFolder.selectedForms = [];
    });

    //* Move Task Forms:
    builder.addCase(moveForms.pending, (state, { meta }) => {
      const originTable = state.formFolders[meta.arg.originId];
      const destinationTable = state.formFolders[meta.arg.destinationId];
      originTable.loading = true;
      originTable.updated = undefined;
      destinationTable.loading = true;
      destinationTable.updated = undefined;
    });
    builder.addCase(moveForms.rejected, (state, { meta }) => {
      const originTable = state.formFolders[meta.arg.originId];
      const destinationTable = state.formFolders[meta.arg.destinationId];
      originTable.loading = false;
      destinationTable.loading = false;
    });
    builder.addCase(moveForms.fulfilled, (state, { meta }) => {
      const originTable = state.formFolders[meta.arg.originId];
      const destinationTable = state.formFolders[meta.arg.destinationId];

      // handle possible pagination change
      originTable.totalForms -= meta.arg.formIds.length;
      // Change page if it's the last form in the page (page > 0):
      if (state.formSearchQuery) {
        originTable.totalFoundForms -= meta.arg.formIds.length;
        if (originTable.totalFoundForms % 20 === 0 && originTable.page > 0) originTable.page--;
      } else {
        if (originTable.totalForms % 20 === 0 && originTable.page > 0) originTable.page--;
      }

      destinationTable.totalForms += meta.arg.formIds.length;
      if (state.formSearchQuery) destinationTable.totalFoundForms += meta.arg.formIds.length;

      originTable.updated = true;
      destinationTable.updated = true;
    });

    //* Get Folder Forms:
    builder.addCase(getFolderForms.pending, (state, { meta }) => {
      if (!Object.prototype.hasOwnProperty.call(state.formFolders, meta.arg)) return; // Folder was deleted
      state.formFolders[meta.arg].loading = true;
    });
    builder.addCase(getFolderForms.rejected, (state, { meta }) => {
      if (!Object.prototype.hasOwnProperty.call(state.formFolders, meta.arg)) return; // Folder was deleted
      state.formFolders[meta.arg].loading = false;
    });
    builder.addCase(getFolderForms.fulfilled, (state, { meta, payload }) => {
      const formFolder = state.formFolders[meta.arg];
      formFolder.taskForms = payload.forms;
      if (state.formSearchQuery) {
        formFolder.totalFoundForms = payload.total_forms;
        formFolder.visible = payload.total_forms > 0;
      } else {
        formFolder.totalForms = payload.total_forms;
      }
      formFolder.open = payload.total_forms > 0;
      formFolder.selectedForms = [];
      formFolder.loading = false;
      formFolder.updated = true;
    });

    //* Get All Folder Forms:
    builder.addCase(getAllFolderForms.pending, (state, { meta }) => {
      if (!Object.prototype.hasOwnProperty.call(state.formFolders, meta.arg)) return; // Folder was deleted
      state.formFolders[meta.arg].loading = true;
    });
    builder.addCase(getAllFolderForms.rejected, (state, { meta }) => {
      if (!Object.prototype.hasOwnProperty.call(state.formFolders, meta.arg)) return; // Folder was deleted
      state.formFolders[meta.arg].loading = false;
    });
    builder.addCase(getAllFolderForms.fulfilled, (state, { meta, payload }) => {
      const formFolder = state.formFolders[meta.arg];
      formFolder.taskForms = payload.forms;
      formFolder.totalForms = payload.total_forms;
      formFolder.loading = false;
      formFolder.updated = true;
    });

    //* Search Forms:
    builder.addCase(searchForms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchForms.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(searchForms.fulfilled, (state, { payload }) => {
      const foldersWithFoundForms = Object.keys(payload).map((folderId) => parseInt(folderId));

      // hide folders with no found forms:
      state.formFolders = Object.values(state.formFolders).reduce((formFolders, folder) => {
        return {
          ...formFolders,
          [folder.id]: {
            ...folder,
            visible: foldersWithFoundForms.includes(folder.id) || folder.id === 0,
          },
        };
      }, state.formFolders);

      // reset main table forms and pagination:
      state.formFolders[0].taskForms = [];
      state.formFolders[0].page = 0;
      state.formFolders[0].totalFoundForms = 0;

      // replace the forms in the folders with the found forms:
      state.formFolders = foldersWithFoundForms.reduce((formFolders, folderId) => {
        return {
          ...formFolders,
          [folderId]: {
            ...formFolders[folderId],
            taskForms: payload[folderId].forms,
            totalFoundForms: payload[folderId].total_forms,
            page: 0,
            open: false,

            updated: true,
          },
        };
      }, state.formFolders);

      state.loading = false;
    });

    //* Get Folders with Pending Signatures:
    builder.addCase(getFoldersWithPendingSignatures.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFoldersWithPendingSignatures.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getFoldersWithPendingSignatures.fulfilled, (state, { payload }) => {
      state.formFolders = Object.values(state.formFolders).reduce((formFolders, folder) => {
        return {
          ...formFolders,
          [folder.id]: {
            ...folder,
            pendingSignatureForms: payload[folder.id],
          },
        };
      }, state.formFolders);
      state.loading = false;
    });
  },
});

export const {
  cleanTaskForms,
  updateFormSearchQuery,
  clearFormSearch,
  updateFormFolderPage,
  toggleOpenFolder,
  toggleSelectedForm,
  toggleAllSelectedForms,
} = taskFormsSlice.actions;
export {
  getAllTaskForms,
  getFolders,
  toggleHideTaskForm,
  bulkUpdateFormVisibility,
  cloneTaskForm,
  deleteTaskForm,
  bulkDeleteForms,
  renameFolder,
  createFolder,
  deleteFolder,
  getFolderForms,
  getAllFolderForms,
  searchForms,
  moveForms,
  getFoldersWithPendingSignatures,
};

//* Custom selectors:
// Select only visible folders:
export function selectFilteredFolders(state: RootState) {
  return Object.values(state.taskForms.formFolders).filter((folder) => folder.id && folder.visible);
}

// Interface to resemble previous "folders" state:
export function selectFolders(state: RootState) {
  return Object.values(state.taskForms.formFolders)
    .filter((folder) => folder.id !== 0)
    .map((folder) => {
      return {
        ...folder,
        forms: folder.allFormsIds,
        enabledForms: folder.allFormsIds.filter(
          (formId) => !state.taskForms.taskForms.find((form) => form.id === formId)?.hidden,
        ),
      };
    });
}

export function selectFolder(state: RootState, folderId: number) {
  return state.taskForms.formFolders[folderId];
}

export default taskFormsSlice.reducer;
