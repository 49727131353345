import { useReducer, ReactNode, useEffect, useRef, useContext, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getLocations } from '../../../store/modules/locations.slice';
import { getGroups } from '../../../store/modules/groups.slice';
import { getAllTaskForms } from '../../../store/modules/taskForms.slice';
import {
  newUserInformationReducer,
  newUserGroupReducer,
  newUserFormReducer,
  newUserLocationReducer,
  NewMobileUserInformationState,
  NewMobileUserInformationAction,
  NewMobileUserGroupState,
  NewMobileUserGroupAction,
  NewMobileUserFormState,
  NewMobileUserFormAction,
  NewMobileUserLocationState,
  NewMobileUserLocationAction,
} from './newMobileUserDataReducer';
import { Dispatch, createContext } from 'react';
import {
  PermissionsAction,
  PermissionsReducer,
  PermissionsState,
  permissionsStateBase,
} from '../mobileUsersPermissionsSelectorReducer';
import { currentUserApi, mobileUsersApi } from '../../../axios';
import { MobileUser } from '../../../types';
import { getQueryParams } from '../../../utils/utils';
import { emailRegex } from '../../../utils/validations';
import { useNationalIdNumber, useDebounce } from '../../../hooks';
import { NationalIdNumber } from '../../../types';

interface NewUserDataContextValue {
  informationState: NewMobileUserInformationState;
  informationDispatch: Dispatch<NewMobileUserInformationAction>;
  groupState: NewMobileUserGroupState;
  groupStateDispatch: Dispatch<NewMobileUserGroupAction>;
  formState: NewMobileUserFormState;
  formStateDispatch: Dispatch<NewMobileUserFormAction>;
  locationState: NewMobileUserLocationState;
  locationStateDispatch: Dispatch<NewMobileUserLocationAction>;
  permissionsState: PermissionsState;
  permissionsStateDispatch: Dispatch<PermissionsAction>;
  signatureName: string;
  setSignatureName: (value: string) => void;
  signatureEmail: string;
  setSignatureEmail: (value: string) => void;
  signatureEmailError: string;
  signatureNationalIdNumber: NationalIdNumber;
  setSignatureNationalIdNumber: (value: string) => void;
  setSignatureCountry: (value: string) => void;
  handleFetchExistingData: () => void;
  isEditing: boolean;
  currentUserId: number;
  completeSignatureWarning: boolean;
  existingUser?: ExistingUser;
}

interface ExistingUser {
  groups: number[];
  forms: number[];
  locations: number[];
  info: MobileUser;
  signature: {
    full_name: string;
    personal_email: string;
    rut: string;
  };
  pending_signature_forms: number[];
  pending_signature_groups: number[];
}

const NewMobileUserDataContext = createContext<NewUserDataContextValue>(null);

export const NewMobileUserDataContextProvider = ({ children }: { children: ReactNode }) => {
  const initialGroupSelectionFilled = useRef(false);
  const initialFormSelectionFilled = useRef(false);
  const existingFormSelectionFilled = useRef(false);
  const existingLocationSelectionFilled = useRef(false);
  const existingGroupSelectionFilled = useRef(false);
  const isEditing = useRef<boolean>(null);

  const [existingUser, setExistingUser] = useState<ExistingUser>(null);
  const [currentUserId, setCurrentUserId] = useState<number>(null);
  const [signatureName, setSignatureName] = useState('');
  const [signatureEmail, setSignatureEmail] = useState('');
  const [signatureEmailError, setSignatureEmailError] = useState('');
  const {
    id: signatureNationalIdNumber,
    setNationalIdNumber: setSignatureNationalIdNumber,
    setCountry: setSignatureCountry,
  } = useNationalIdNumber('');
  const [completeSignatureWarning, setCompleteSignatureWarning] = useState(false);

  const dispatchRedux = useAppDispatch();
  const { groups } = useAppSelector((state) => state.groups);

  const allowedPersonalEmailDomains = [
    'gmail.com',
    'outlook.com',
    'hotmail.com',
    'live.com',
    'aol.com',
    'yahoo.com',
  ];

  function getDomainFromEmail(email: string) {
    return email?.toLowerCase().split('@')[1];
  }

  useEffect(() => {
    const loadBaseData = async () => {
      await dispatchRedux(getAllTaskForms());
      await dispatchRedux(getGroups());
      await dispatchRedux(getLocations({}));
      const { id } = await currentUserApi.getCurrentUserName();
      setCurrentUserId(id);
    };
    loadBaseData();
  }, [dispatchRedux]);

  const [informationState, informationDispatch] = useReducer(newUserInformationReducer, {
    name: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    oldPassword: '',
    newPassword: '',
  });

  const [groupState, groupStateDispatch] = useReducer(newUserGroupReducer, {
    createdGroupName: '',
    createdGroupFormIds: [],
    groupsSelection: [],
  });

  const [formState, formStateDispatch] = useReducer(newUserFormReducer, {
    formsSelection: [],
  });

  const [locationState, locationStateDispatch] = useReducer(newUserLocationReducer, {
    locationsSelectionIds: [],
  });

  const [permissionsState, permissionsStateDispatch] = useReducer(PermissionsReducer, {
    permissions: permissionsStateBase,
  });

  const checkEmailValidation = useCallback(async () => {
    if (!emailRegex.test(signatureEmail) && signatureEmail !== '') {
      setSignatureEmailError('REGEX_INVALID');
      return;
    }

    const emailsAreTheSame =
      signatureEmail?.toLowerCase() === informationState.email?.toLowerCase();
    const emailDomain = getDomainFromEmail(signatureEmail);
    const sameEmailIsAllowed = allowedPersonalEmailDomains.includes(emailDomain);

    if (emailsAreTheSame) {
      setSignatureEmailError(sameEmailIsAllowed ? null : 'COMPANY_EMAIL');
      return;
    }

    const { email_exists } = await mobileUsersApi.validateNewUserEmail(signatureEmail);

    setSignatureEmailError(email_exists ? 'IN_USE' : null);
  }, [signatureEmail, informationState.email]);

  const handleCheckEmailValidation = useDebounce(checkEmailValidation, 500);

  const handleSignatureEmailChange = (email: string) => {
    setSignatureEmail(email);
    handleCheckEmailValidation();
  };

  useEffect(() => {
    // If the user starts setting the signature, all fields are required.
    const signatureFields = [signatureName, signatureEmail, signatureNationalIdNumber.value];
    const filledOutFields = signatureFields.filter((field) => field !== '').length;
    const missingFields = filledOutFields > 0 && filledOutFields < signatureFields.length;
    const missingNameOrLastName =
      filledOutFields > 0 && (!informationState.name || !informationState.lastName);
    const errorCondition =
      missingFields ||
      signatureEmailError ||
      !signatureNationalIdNumber.valid ||
      missingNameOrLastName;
    if (errorCondition) setCompleteSignatureWarning(true);
    else setCompleteSignatureWarning(false);
  }, [
    signatureName,
    signatureEmail,
    signatureNationalIdNumber,
    signatureEmailError,
    informationState.name,
    informationState.lastName,
  ]);

  const renamePermissions: { [key: string]: string } = {
    answers: 'viewFormAnswers',
    chat: 'chat',
    tasks: 'submitFormAnswers',
    assigned: 'viewAssignedForms',
    evaluate: 'changeFormStatus',
    metadata: 'createMetadata',
    modify: 'editFormAnswers',
    edit_forms: 'editForms',
    evaluate_delete: 'evaluateDelete',
    users: 'viewAndCreateUsers',
    export: 'exportData',
  };

  const handleFetchExistingData = async () => {
    const { user_id: mobileUserId } = getQueryParams();
    if (!mobileUserId) {
      isEditing.current = false;
      return;
    }
    isEditing.current = true;
    const {
      mobile_user: userInfo,
      group: userGroups,
      location: userLocations,
      form: userForms,
      signature: userSignature,
      pending_signature_forms: pendingSignatureForms,
      pending_signature_groups: pendingSignatureGroups,
    } = await mobileUsersApi.getUserInfo(mobileUserId);
    setExistingUser({
      info: userInfo,
      groups: userGroups.id,
      locations: userLocations.id,
      forms: userForms.id,
      signature: userSignature,
      pending_signature_forms: pendingSignatureForms.id,
      pending_signature_groups: pendingSignatureGroups.id,
    });

    // Set existing personal information:
    informationDispatch({ type: 'update_user_name', value: userInfo.name });
    informationDispatch({ type: 'update_user_last_name', value: userInfo.last_name });
    informationDispatch({ type: 'update_user_email', value: userInfo.email });
    informationDispatch({ type: 'update_user_phone', value: userInfo.phone });

    // Set existing permissions:
    Object.keys(userInfo).forEach((key) => {
      if (!['id', 'name', 'last_name', 'email', 'phone'].includes(key)) {
        if (['answers', 'chat', 'tasks', 'assigned'].includes(key))
          permissionsStateDispatch({
            type: 'update_basic_permission',
            permissionKey: renamePermissions[key],
            value: true,
          });
        else if (['users', 'export'].includes(key))
          permissionsStateDispatch({
            type: 'update_admin_permission',
            permissionKey: renamePermissions[key],
            value: true,
          });
        else
          permissionsStateDispatch({
            type: 'update_advanced_permission',
            permissionKey: renamePermissions[key],
            value: true,
          });
      }
    });

    // Set existing signature:
    if (userSignature) {
      setSignatureName(userSignature.full_name);
      setSignatureEmail(userSignature.personal_email);
      setSignatureNationalIdNumber(userSignature.rut.replace(/[-.]/g, ''));
      setSignatureCountry(userSignature.country);
    } else {
      setSignatureCountry('CL');
    }
  };

  // --> After the groups, locations and forms have been fetched, we set the reducers state with that info:
  useEffect(() => {
    const userEditingAndFormsNotFilled =
      isEditing.current && existingUser && !existingFormSelectionFilled.current;
    const newUserAndFormsNotFilled = !isEditing.current && !initialFormSelectionFilled.current;
    if (userEditingAndFormsNotFilled) {
      formStateDispatch({ type: 'update_forms_selection', value: existingUser.forms });
      existingFormSelectionFilled.current = true;
    } else if (newUserAndFormsNotFilled) {
      initialFormSelectionFilled.current = true;
      formStateDispatch({
        type: 'update_forms_selection',
        value: [],
      });
    }
  }, [existingUser]);

  useEffect(() => {
    if (groups.length > 0 && !existingGroupSelectionFilled.current) {
      if (isEditing.current && existingUser) {
        // Set existing groups:
        const userGroupObjects = groups.map((group) => {
          return { ...group, selected: existingUser.groups.includes(group.id) };
        });
        groupStateDispatch({
          type: 'update_groups_selection',
          updatedGroupsSelection: userGroupObjects,
        });
        existingGroupSelectionFilled.current = true;
      } else if (!isEditing.current && !initialGroupSelectionFilled.current) {
        const emptyGroupSelection = groups.map((group) => ({
          ...group,
          selected: false,
        }));
        groupStateDispatch({
          type: 'update_groups_selection',
          updatedGroupsSelection: emptyGroupSelection,
        });
        initialGroupSelectionFilled.current = true;
      }
    }
  }, [groups, existingUser]);

  useEffect(() => {
    // Set the user's existing selected locations:
    if (existingUser && !existingLocationSelectionFilled.current) {
      // TODO: is `existingLocationSelectionFilled` necessary????
      locationStateDispatch({
        type: 'update_locations_selection',
        value: existingUser.locations,
      });
      existingLocationSelectionFilled.current = true;
    }
  }, [existingUser]);

  // Prepare the variables and dispatchers we will provide:
  const newMobileUserContextReturnValue = {
    informationState,
    informationDispatch,
    groupState,
    groupStateDispatch,
    formState,
    formStateDispatch,
    locationState,
    locationStateDispatch,
    permissionsState,
    permissionsStateDispatch,
    signatureName,
    setSignatureName,
    signatureEmail,
    setSignatureEmail: handleSignatureEmailChange,
    signatureEmailError,
    signatureNationalIdNumber,
    setSignatureNationalIdNumber,
    setSignatureCountry,
    handleFetchExistingData,
    isEditing: isEditing.current,
    currentUserId,
    completeSignatureWarning,
    existingUser,
  };

  return (
    <NewMobileUserDataContext.Provider value={newMobileUserContextReturnValue}>
      {children}
    </NewMobileUserDataContext.Provider>
  );
};

export const useNewMobileUserContext = () => useContext(NewMobileUserDataContext);
