import { Backdrop, Box, SxProps, Theme } from '@mui/material';
import { CSSFlexibleDirection } from '../../style/cssTypes';
import { DSSpinner } from './logoSpinner';

const customStyles: Record<string, SxProps<Theme>> = {
  backdrop: {
    zIndex: 1000,
  },
  loadingModal: {
    display: 'flex',
    flexDirection: 'column' as CSSFlexibleDirection,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '15px',
    borderRadius: '10px',
    height: '100%',
    width: '100%',
  },
};

export function LoadingScreen({ description }: { description?: React.ReactNode }) {
  return (
    <Backdrop
      open
      sx={customStyles.backdrop}
    >
      <Box sx={customStyles.loadingModal}>
        <DSSpinner />
        {description}
      </Box>
    </Backdrop>
  );
}
