import { useCallback, useContext } from 'react';
import { UserDataContext } from '../providers';

export function usePermissionFlags() {
  const { permissions } = useContext(UserDataContext);

  const checkPermission = useCallback(
    (permissionToCheck: string) => {
      if (permissions) {
        return permissions.some((permission: string) => permissionToCheck === permission);
      }
      return false;
    },
    [permissions],
  );

  return {
    permissions,
    checkPermission,
  };
}
