import axios from 'axios';
import { FirebaseAuthConfig } from '../types/firestore';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

async function getFirebaseAuthTokens() {
  const getFirebaseAuthUrl = '/users/get_firebase_auth';

  try {
    const response = await axios.get<FirebaseAuthConfig>(getFirebaseAuthUrl, {
      withCredentials: true,
    });
    const { data } = response;

    return data;
  } catch (error) {
    console.error(`Error retrieving Firebase Auth - ${error}`);
  }
}

export const firestoreApi = {
  getFirebaseAuthTokens,
};
