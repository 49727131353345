import { TaskNotification } from '../TaskAssignerCreate/addnotification';

export const formatNotifications = (notifString: string): TaskNotification[] => {
  const notifsList = notifString.split('-');
  return notifsList.map((notif: string) => {
    const splitNotif = notif.split(':').map((value: string) => parseInt(value));
    return {
      type: splitNotif[0] == 1 ? 'notification' : 'email',
      time: splitNotif[1],
      period: splitNotif[2],
    };
  });
};
