import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { templatesApi } from '../../axios/templates';
import { QuestionInterface } from '../../types/oldWebForms';
import { TaskForm } from '../../types';

interface TemplateState {
  templates: TaskForm[];
  templateCount: number;
  categories: string[];
  category: string;
  page: number;
  templateSearchQuery: string;
  loading: boolean;
  currentTemplateId?: number;
  currentQuestion?: QuestionInterface;
  error?: string;
  locale: string;
}

const initialTemplateState: TemplateState = {
  templates: [],
  templateCount: 0,
  categories: [],
  category: '',
  page: 0,
  templateSearchQuery: '',
  loading: false,
  error: undefined,
  locale: '',
};

const getTemplates = createAsyncThunk(
  'getTemplates',
  async (
    _,
    { getState }, // This comes from the payloadCreator. See https://redux-toolkit.js.org/api/createAsyncThunk#payloadcreator
  ) => {
    const store = getState() as Record<string, TemplateState>;
    return await templatesApi.getTemplates(
      store.templates.page,
      store.templates.templateSearchQuery,
      store.templates.category,
      store.templates.locale,
    );
  },
);

const getCategories = createAsyncThunk(
  'getCategories',
  async (
    _,
    { getState }, // This comes from the payloadCreator. See https://redux-toolkit.js.org/api/createAsyncThunk#payloadcreator
  ) => {
    const store = getState() as Record<string, TemplateState>;
    return await templatesApi.getCategories(store.templates.locale);
  },
);

export const templatesSlice = createSlice({
  name: 'taskForms',
  initialState: initialTemplateState,
  reducers: {
    updateTemplatesPage: (state, action) => {
      state.page = action.payload;
    },
    updateSearchQuery: (state, action) => {
      state.templateSearchQuery = action.payload;
    },
    updateCategory: (state, action) => {
      state.category = action.payload;
    },
    updateCurrentTemplate: (state, action) => {
      state.currentTemplateId = action.payload;
    },
    updateQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },
    updateLocale: (state, action) => {
      state.locale = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplates.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getTemplates.rejected, (state) => {
      state.loading = false;
      state.error = 'Error fetching templates';
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.templates = action.payload.templates;
      state.templateCount = action.payload.total_templates;
    });
    builder.addCase(getCategories.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getCategories.rejected, (state) => {
      state.loading = false;
      state.error = 'Error fetching categories';
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.categories = action.payload;
    });
  },
});

export const {
  updateTemplatesPage,
  updateSearchQuery,
  updateCategory,
  updateCurrentTemplate,
  updateQuestion,
  updateLocale,
} = templatesSlice.actions;
export { getTemplates, getCategories };

export default templatesSlice.reducer;
