import EXIF from 'exif-js';

const NORMAL_ORIENTATION = 1;

export async function rotateImage(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        //@ts-ignore img is not a type of HTMLElement
        EXIF.getData(img, function (this: HTMLImageElement) {
          const orientation = EXIF.getTag(this, 'Orientation');
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          switch (orientation) {
            case undefined:
            case NORMAL_ORIENTATION:
              return resolve(file);
            default:
              canvas.width = img.width;
              canvas.height = img.height;
              break;
          }
          ctx?.translate(canvas.width / 2, canvas.height / 2);
          ctx?.drawImage(img, -img.width / 2, -img.height / 2);
          ctx?.canvas.toBlob(
            (blob) => {
              const newFile = new File([blob as Blob], file.name, {
                type: 'image/jpeg',
              });
              resolve(newFile);
            },
            'image/jpeg',
            1,
          );
        });
      };
      img.src = event.target?.result as string;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}
