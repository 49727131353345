import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

export interface PremiumFlag {
  status: 'demo' | 'active' | 'expired' | 'cancelled';
  trial_days?: number;
  activation_date: Date;
}
interface FeatureFlagsInterface {
  flags: string[];
  premium_flags?: Record<string, PremiumFlag>;
}

interface PermissionsInterface {
  permissions: string[];
}

export interface CurrentUser {
  id: number;
  username: string;
  email: string;
  last_name: string;
  is_support?: boolean;
  is_admin?: boolean;
  is_superadmin?: boolean;
  project?: {
    name: string;
    block_editing_creating_forms: boolean;
    forms_allowed_per_account: number;
  };
}

async function getCurrentUserFlags() {
  const getCurrentUserFlagsUrl = '/users/show_feature_flags';
  try {
    const response = await axios.get<FeatureFlagsInterface>(getCurrentUserFlagsUrl, {
      withCredentials: true,
    });
    const { data } = response;
    return data;
  } catch (error) {
    console.error(`Error retrieving feature flags - ${error}`);
    return { flags: [] };
  }
}

async function getCurrentUserPermissions() {
  const getCurrentUserPermissionsUrl = '/show_permission_flags';
  try {
    const response = await axios.get<PermissionsInterface>(getCurrentUserPermissionsUrl, {
      withCredentials: true,
    });
    const { data } = response;
    return data.permissions;
  } catch (error) {
    console.error(`Error retrieving permissions - ${error}`);
    return [];
  }
}

async function currentUserLogout() {
  const logoutUrl = '/react_logout';
  const response = await axios.get(logoutUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getCurrentUserName() {
  const getUserUrl = '/react_get_username';
  const response = await axios.get<CurrentUser>(getUserUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

export const currentUserApi = {
  getCurrentUserFlags,
  getCurrentUserPermissions,
  currentUserLogout,
  getCurrentUserName,
};
